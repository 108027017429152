<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>Academics</li><span class="arrow">></span>
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1>
                  
                 
   
   
              </div>
              
          <div class="sidebar">
              <AcademicsQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';
import AcademicsQuickLinks from "../../components/page/AcademicsQuickLinks.vue";
import Footer from "../../components/page/Footer.vue";





export default {
    name: 'Background',    
    components: { TopBar, Header, NavBar, RecentPosts, AcademicsQuickLinks, Footer },
    setup(){
        const title= ref('Guidance & Counselling')
        return {title}
    }
  
}
</script>

<style scoped>

</style>